.carousel .thumb.selected, .carousel .thumb:hover {
    border: 2px solid white;
}
.carousel .thumb {
    border: 2px solid #0c1a32;
}
.carousel .slide .legend{
    background-color: #0c1a32;
    bottom: 20px;
}
@media only screen and (min-width: 768px){
    .carousel .slide .legend{
        width: 60%;
        left: 65%;
    }
}
.carousel:hover .slide .legend{
    opacity: 0.9;
}
.carousel .control-dots .dot{
    box-shadow: none;
    opacity: 0.6;
}
.carousel .action{
    width: auto;
    position: absolute;
    top: 0;
    margin: 10px 30px;
}
.carousel .action.left{
    left: 0;
}
.carousel .action.right{
    right: 0;
}