.main-header .btn-primary-light.push-btn{
    background-color: #0c1a32;
}
.main-header .logo .logo-lg{
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.dark-skin .main-header .navbar .nav > li > button {
    background-color: #0c1a32;
}
.main-header .r-side .app-menu, .main-header .r-side .dropdown > button {
    margin: 18px 5px;
}
.main-header .navbar-nav > li.dropdown > .dropdown-menu > li .menu{
    display: flex;
    flex-direction: column;
}
.main-header .menu.sm-scrol > li{
    margin: 8px 0;
}
.main-header .menu.sm-scrol a{
    margin: 0;
}
.theme-primary.dark-skin .sidebar-menu > li.active button {
    color: white;
}
.theme-primary.dark-skin .sidebar-menu > li.active button > i {
    color: white;
}
.theme-primary.dark-skin .sidebar-menu > li.active > button {
    background-color: #1c2e4c;
}

.dropdown-menu > li > button {
    color: #b5b5c3;
    width: 100%;
}
.menu-right{
    position: relative;
}
.menu-right .dropdown-menu{
    width: 170px;
    max-width: 250px;
    padding: 0;
    margin: 0;
    top: calc(100% + 18px);
    border: none;
    position: absolute;
}
.menu-right .dropdown-menu .menu > li a{
    width: 100%;
}
.menu-right .dropdown-menu .menu > li a i{
    margin-right: 8px;
}
.menu-right .dropdown-menu .menu > li:hover{
    background-color: rgba(0, 0, 0, 0.1);
}

.slimScrollDiv button {
    color: #b5b5c3;
    padding: 0;
    background-color: transparent;
    width: 100%;
    text-align: left;
}
.slimScrollDiv button:hover{
    color:#6cbbfa;
}
.slimScrollDiv button i{
    margin-right: 10px;
}