.treeview-menu{
    display: block;
}
.multinav .multinav-scroll.ps{
    height: 100%;
    overflow-y: auto;
}
.multinav .multinav-scroll.ps::-webkit-scrollbar {
    width: 6px;
    background: rgba(241, 241, 241, 0.05);
    border-radius: 3px;
}
.multinav .multinav-scroll.ps::-webkit-scrollbar-thumb {
    background: rgb(215, 215, 215);
    border-radius: 3px;
}
.sidebar-mini.sidebar-collapse .sidebar-menu > li > a i{
    margin-right: 0; 
    margin-left: 5px;
}
.theme-primary.dark-skin .sidebar-menu li a:hover,
.theme-primary.dark-skin .sidebar-menu li button:hover {
    color: #cdf8ff!important;
}
.dark-skin .sidebar-menu > li:hover > button > i,
.dark-skin .sidebar-menu > li:hover > a > i {
    color: #ffffff;
    border: 0px solid #cdf8ff!important;
}
.sidebar-menu > li > button > i,
.sidebar-menu > li > a > i {
    font-size: 1.2rem;
}
.sidebar-menu > li > button{
    padding: 10px 15px;
    display: block;
    border-radius: 100px 0 0 100px;
    position: relative;
    font-weight: 500;
    opacity: 0.9;
    white-space: nowrap;
    align-items: center;
    line-height: 25px;
    background-color: transparent;
    width: 100%;
    text-align: left;
}
.sidebar-menu li > button > .pull-right-container,
.sidebar-menu li > a > .pull-right-container {
    position: absolute;
    right: 10px;
    margin-top: -7px;
    top: 50%;
}
.sidebar-menu li > button > .pull-right-container > i {
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 10px;
    -webkit-transition: transform .5s ease;
    -o-transition: transform .5s ease;
    transition: transform .5s ease;
    transform: rotate(0deg);
    font-size: 0.85em;
    float: right;
}
.sidebar-menu .menu-open > button > .pull-right-container > i,
.sidebar-menu .menu-open > a > .pull-right-container > i {
    transform: rotate(90deg);
}
.dark-skin .sidebar button{
    color: #b5b5c3;
}
@media (min-width: 768px){   
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > button {
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
    .sidebar-collapse .sidebar-menu > li > button {
        padding: 8px 12px;
    }
}

.sidebar-mini.sidebar-collapse .sidebar-menu > li > button i {
    margin-right: 0;
    margin-left: 5px;
}
@media (min-width: 768px){
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > button i {
        margin-right: 0;
    }
}
.dark-skin .sidebar-menu > li > button > i {
    color: #b5b5c3;
}
.sidebar-menu > li > button > i {
    font-size: 1.2rem;
}
.sidebar-menu > li > button > i {
    width: 30px;
    line-height: 28px;
    font-size: 1.5714285714rem;
    display: inline-block;
    vertical-align: middle;
    color: #172b4c;
    text-align: center;
    border-radius: 10px;
    margin-right: 20px;
    background-color: transparent;
}
.dark-skin.sidebar-mini.sidebar-collapse .sidebar-menu > li > button > span {
    background-color: #0c1a32 !important;
    color: #ffffff;
}
@media (min-width: 768px){
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:not(.treeview) > button > span {
        border-top-right-radius: 5px;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > button > span {
        border-top-right-radius: 5px;
        display: none !important;
        transform: translateZ(0);
    }
}
.sidebar-menu li > button > span {
    top: 1px;
    position: relative;
}
.sidebar-menu > li .treeview-menu li{
    border-radius: 100px 0 0 100px;
}
.sidebar-menu > li .treeview-menu li.active {
    background-color: rgba(0, 20, 37, 0.75);
    color: white;
}
.sidebar-collapse .sidebar-menu > li.active > button {
    border-radius: 10px;
}
.theme-primary.dark-skin .sidebar-menu > li .treeview-menu li.active > a {
    color: white!important;
}