.table-responsive{
    position: relative;
}
table tr th.actions-title{
    width: 15%;
}
table tr td.actions-container{
    gap: 5px;
    justify-content: space-around;
}
table tr td.actions-container > button{
    width: 40px;
}
.seleccionado { 
    
    background: gray;
    color: white; 
}

.dark-skin .simple-table > tbody > tr > td{
    border: 0;
}
.dark-skin .simple-table > tbody > tr{
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
