span.__rdt_custom_sort_icon__{
    transition: all ease-in-out 0.25s;
}
span.__rdt_custom_sort_icon__ i{
    font-size: 15px!important;
    margin-left: 5px!important;
}
span.desc.__rdt_custom_sort_icon__{
    transform: rotateX(180deg)!important;
    margin-top: 9px;
}
.dark-skin .as-react-table .input-group .input-group-addon{
    background-color: transparent;
}
.table>:not(:last-child)>:last-child>*{
    border-bottom-color: rgba(255, 255, 255, 0.5);
}
.table th{
    color: #2196F3
}
@media only screen and (min-width: 768px){
    .table_filter{
        width: 100%!important;
    }
}
@media only screen and (min-width: 1024px){
    .table_filter{
        width: 90%!important;
    }
}
.pagination .page-item .page-link{
    padding: 0.5rem 0.75rem;
}
.pagination .page-item a input{
    background-color: transparent;
    color: white;
    outline: none;
    border: none;
}
.pagination .page-item a input:focus{
    outline: none;
    border: none;
}