.theme-primary .customtab2 li .nav-link:hover,
.theme-primary .customtab2 li .nav-link:focus,
.theme-primary .customtab2 li .nav-link:active{
    border-bottom-color: #fb5ea8;
    background-color: #fb5ea8;
    color: #fff!important;
}
.theme-primary .customtab2 li.nav-item > button{
    width: 100%;
}
.nav-tabs .nav-link.active{
    color: #fff!important;
}
.theme-primary .customtab2 li .nav-link{
    text-align: left;
}