.section-bx .info-container:hover {
    background-color: rgb(164, 95, 248)!important;
}
.section-bx .info-container .bx-dec {
    display: none;
}
.section-bx .info-container:hover .bx-dec {
    display: block;
}
.section-bx .info-container .section-dec {
    position: absolute;
    background-color: rgba(164, 95, 248, 0.85);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 5px 15px 4px rgb(0 135 90 / 25%);
    margin-top: 5px;
    margin-left: 10px;
    z-index: 10;
    text-align: center;
}
.section-bx .info-container .section-dec .box-img{
    cursor: pointer;
}
.section-bx .info-container .section-dec img{
    background-color: rgba(164, 95, 248, 0.95);
}
.section-bx .info-container .section-dec .svg-container {
    max-width: 100px;
    margin-right: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(164, 95, 248, 0.95);
    color: #fff;
}
.section-bx .info-container .section-dec .svg-container svg{
    height: 75px;
    width: 75px;
}
.section-bx .info-container .section-dec .hover-big{
    transition: all 0.25s ease-in-out;
}
.section-bx .info-container .section-dec .hover-big:hover{
    transform: scale(1.05);
}
.section-bx .info-container .dec button{
    display: block;
    width: 100%;
}