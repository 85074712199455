.modal-header .btn-close{
    color: white;
    background: none;
    position: relative;
    background-color: #081835;
}
.modal-header .btn-close::after{
    content: '';
    width: 90%;
    height: 2px;
    position: absolute;
    top: 50%;
    transform: rotateZ(45deg);
    background-color: white;
    left: 1px;
}
.select-display{
  display: none;
}
.label-mt{
  margin-top: 5.5px;
}
.modal-header .btn-close::before{
    content: '';
    width: 90%;
    height: 2px;
    position: absolute;
    top: 50%;
    transform: rotateZ(-45deg);
    background-color: white;
    left: 1px;
}

.mycheck {
    border: 3px solid red;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    appearance: none;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    padding: 0;
    user-select: none;
    flex-shrink: 0;
    color: #2563eb;
    background-color: #888;
    border-color: #6b7280;
    border-width: 1px;
  }

  .mycheck {
    cursor: pointer;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    width: 48px;
    height: 48px;
    appearance: none;
    border: 2px solid #888;
    transition: background-color 0.3s ease-in-out;
  }

  .mycheck:checked {
    background-color: rgb(236 72 153);
  }

  .mycheck:focus {
    border-color: rgb(80, 67, 250);
  }

  .mycheck:disabled {
    background-color: rgb(198, 198, 198);
    background-image: none;
  }

  .mycheck:disabled:checked {
    background-color: rgb(198, 198, 198);
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }

  .mycheck2 {
    cursor: pointer;
    background-color: #fff;
    color: #fff;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    width: 24px;
    height: 24px;
    appearance: none;
    border: 2px solid #888;
    background-position: 0 -2rem;
    background-size: 100%;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
  }

  .mycheck2:checked {
    background-color: rgb(75, 156, 13);
    color: rgb(75, 156, 13);
    background-position: 0 0;
  }