.section-full-heigth{
  height: 100vh;
}
.section-full-parent{
  height: 100%;
  width: 100%;
}
.dark-skin .form-control,
.dark-skin .form-select{
  background-color: #0c1a32!important;
  color: rgb(207, 207, 207);
  border-color: rgba(207, 207, 207, 0.25);
}
.dark-skin .form-select {
  cursor: pointer;
}
/*------------------------------------
  Custom Boostrap
------------------------------------*/
.toast-header{
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
}
button{
  appearance: none;
  border: none;  
  display: block;
}
/* Tooltip */
.dark-skin .tooltip > .tooltip-inner{
  background-color: #172b4c!important;
  color: white;
}
.dark-skin .tooltip > .arrow{
  color: #172b4c!important;
  background-color: #172b4c!important;
}
/* SweetAlwert */
.swal2-modal .swal2-actions{
  width: 70%;
  flex-direction: row;
}
.swal2-modal .swal2-input-label{
  color: rgb(226, 226, 226);
  font-weight: bold;
}
.swal2-modal .swal2-input{
  color: white;
}
.swal2-modal .swal2-validation-message{
  color: rgb(226, 226, 226);
  background-color: transparent;
}
.swal2-modal .swal2-actions > button{
  flex: 1;
}
/* Drop Area */
.dropzone-ui.dropzone-ui-extra{
  background-color: #0c1a32!important;
  padding: 20px 10px!important;
  border: dashed 2px var(--bs-info)!important;
  cursor: pointer;
  color: rgb(202, 202, 202);
}
.dropzone-ui .file-item-name{
  color: rgb(228, 228, 228)!important;
}
.dz-ui-header svg{
  fill: white;
}

.big-indicators .carousel-indicators button{
  height: 12px;
  margin-left: 5px;
  margin-right: 5px;
}